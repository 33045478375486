import { Link, useLocation } from 'react-router-dom';

function Navbar() {
  const location = useLocation();

  return (
    <nav className="nav">
      <Link to="/" className={location.pathname === "/" ? "active" : ""}>Home</Link>
      <Link to="/about" className={location.pathname === "/about" ? "active" : ""}>About</Link>
      <Link to="/rsvp" className={location.pathname === "/rsvp" ? "active" : ""}>RSVP</Link>
    </nav>
  );
}

export default Navbar;
