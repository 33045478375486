import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './pages/Home';
import About from './pages/About';
import RSVP from './pages/RSVP';
import ThankYou from './pages/ThankYou';
import Gridpoc from './pages/Gridpoc'; 


function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/rsvp" element={<RSVP />} />
        <Route path="/thankyou" element={<ThankYou />} />
        <Route path="/Gridpoc" element={<Gridpoc />} />
        <Route path="*" element={<Home />} />
      </Routes>
    </Router>
  );
}

export default App;
