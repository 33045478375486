import Navbar from '../components/Navbar';

function ThankYou() {
  return (
    <div className='container'>
      <img src="cartoon-turkey.png" alt="Hero" className='hero-image'/>
      <Navbar />
      <div className='rsvp_title'>Thank you for RSVPing!</div>
      <img src="IMG_6157.png" alt="Clara" className='clara-image'/>
      <div className='rsvp_title'>Clara welcomes you.</div>
    </div>
  );
}

export default ThankYou;
