import React, { useState, useEffect } from 'react';

function YourImage({ imageUrl }) {
  const [finalImageUrl, setFinalImageUrl] = useState(imageUrl);

  useEffect(() => {
    const fetchImageUrl = async () => {
      if (imageUrl.includes("getImage")) {
        try {
          const response = await fetch(imageUrl);
          if (!response.ok) throw new Error(`HTTP error! Status: ${response.status}`);
          const data = await response.json();
          setFinalImageUrl(data.imageurl); // Assuming the API returns an object with a property named newImageUrl
        } catch (error) {
          console.error('Error fetching image data:', error);
          // Optionally, set finalImageUrl to a fallback image or keep the original
        }
      }
      else {
        setFinalImageUrl(imageUrl);
      }
    };

    fetchImageUrl();
  }, [imageUrl]); // This effect depends on imageUrl, it re-runs if imageUrl changes

  return (
    <img src={finalImageUrl} alt="Dynamic Content" className="gridimage"/>
  );
}
export default YourImage;
