import React, { useState } from 'react';
import '../gridpoc.css';
import YourImage from '../components/YourImage';

function Gridpoc() {
  const [inputValue, setInputValue] = useState('');
  const [selectedOption, setSelectedOption] = useState("");
  const options = ["0", "250", "500", "1000", "2000", "5000"];

  const [items, setItems] = useState([]);
 // const [colors, setColors] = useState('');
  const [currentColor, setCurrentColor] = useState([]);
  

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleOptionChange = (e) => {
    setSelectedOption(e.target.value);
  };

  const handleSubmit = async () => {
    const queryParams = new URLSearchParams({ ids: inputValue }).toString();
    const firstApiUrl = `https://acr632a3lh.execute-api.us-east-1.amazonaws.com/externalProductList?${queryParams}`;

    try {
      const firstApiResponse = await fetch(firstApiUrl);
      if (!firstApiResponse.ok) throw new Error(`HTTP error! Status: ${firstApiResponse.status}`);
      const firstApiData = await firstApiResponse.json();

      const productIds = firstApiData.map(item => item.productId).join(',');


      
      const secondApiUrl = `https://acr632a3lh.execute-api.us-east-1.amazonaws.com/getInternalProducts?ids=${productIds}`;
      const secondApiResponse = await fetch(secondApiUrl);
      if (!secondApiResponse.ok) throw new Error(`HTTP error on second API! Status: ${secondApiResponse.status}`);
      const secondApiData = await secondApiResponse.json();

      // Want to insert a delay here for 0.5 seconds
      await new Promise(r => setTimeout(r, selectedOption));

      // Update items with additional data from the second API call
      const updatedItems = firstApiData.map(item => {
        const internalProduct = secondApiData.find(internal => internal.productid == item.productId);
        return {
          ...item,
          Price: internalProduct ? internalProduct.price : '6',
          Inventory: internalProduct ? internalProduct.inventory : item.Inventory
        };
      });
      setItems(updatedItems);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const generateRandomColors = (i) => {
    const availableColors = ['blue', 'red', 'yellow', 'green', 'grey', 'black', 'orange', 'purple', 'pink', 'brown', 'cyan', 'magenta'];    
    return availableColors[i];
  };

  function YourComponent (index)  {
    // Your SVG component or any other content
    const fill = generateRandomColors(index.index)
   
    return (
      <div>
        <svg width="175" height="300">
          <rect
            width="175"
            height="300"
            style={{ fill: fill, strokeWidth: 3, stroke: "rgb(0,0,0)" }}
          />
        </svg>
      </div>
    );
  }

  return (
    <div className="container">
      <div className="mb-3">
        <label htmlFor="inputField" className="form-label">Enter IDs (comma-delimited)</label>
        <input 
          type="text" 
          className="form-control" 
          id="inputField" 
          value={inputValue}
          onChange={handleInputChange}
        />
        <div className="listexample">Example: 1,2,3,4,5,6,7,8,9,10,11,12</div>
        <label htmlFor="delayField" className="form-label">Enter Delay ms</label>    
        <select
            className="form-control"
            value={selectedOption}
            onChange={handleOptionChange}
        >
            {options.map((option, index) => (
            <option key={index} value={option}>{option}</option>
            ))}
        </select> <div>
    </div>
      </div>
      <button onClick={handleSubmit} className="btn btn-primary">Submit</button>
      
      <div className="row mt-4">
        {items.map((item, index) => (
          <div key={item.productId} className="col-md-4 mb-3"> {/* Updated key to use productId */}
            <div className="card">
              <div className="card-body">
          
             {/* <YourComponent index={index}/> */}
                <YourImage imageUrl={item.ImageUrl} />
                {item.Label} * {item.Inventory}
                {item.Price ? (
                  <div className="Price">{item.Price}</div>
                ) : (
                  <img className="dots3" src="3dots.gif" alt="Loading..." />
                )}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Gridpoc;
